import * as Styled from './contentPressReleaseStyles';
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import {useState} from 'react';
import ContentfulAssets from '../../hooks/useContentfulAssets';
import calenderIcon from '../../images/calender.svg'
import Blueline from '../blueLine/blueLine';
import isExternalUrl, { addTrailingSlash, isExternalUrlhref } from '../../utils';
import { Link } from 'gatsby';
import { graphql } from 'gatsby'

const ContentPressRelease = (sectionData) => {
    const [showMore, setShowMore] = useState(false);
    const maxItems = showMore? 3 : sectionData?.sectionData?.list?.length;

    const optionsVideostyle = {
        renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node?.data
                return (
                <Styled.Iframe frameBorder="0" className='iframe' width="100%" height="400" src={uri} title={children} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;" allowFullScreen>
                </Styled.Iframe>
            )
            },
        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };

    const optionsPressReleaseStyle = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = ContentfulAssets(node?.data?.target?.sys?.id);
                if (asset) {
                return (
                    <Styled.Image
                    src={asset?.node?.file?.url}
                    alt={asset?.node?.title}
                    />
                );
                }
            },
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p style={{display:'none'}}>{children}</p>
            )
        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };

    const optionsPressReleaseParaStyle = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Styled.Paragraph>{children}</Styled.Paragraph>
            )
        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };


return ( 
    (sectionData?.sectionData?.list?.length > 0) &&
    <Styled.MainDiv id={sectionData?.sectionData?.subtext}>
            <Blueline />
            <Styled.TitleContainer>
            <Styled.LineBehind><Styled.LineBehindSpan>{sectionData?.sectionData?.title}</Styled.LineBehindSpan></Styled.LineBehind>
            </Styled.TitleContainer>
            <Styled.ItemContainer>
                
                {sectionData?.sectionData?.list?.map((item,index)=>{
                    
                    if(item.type === 'video'){
                        return(
                            (index<maxItems) && <Styled.Item key={index}>
                                {documentToReactComponents(
                                JSON.parse(item?.contentDetails?.raw),
                                optionsVideostyle
                                )}
                                <Styled.ItemDetails>
                                    {item?.header && <Styled.Span><Styled.Icon src={calenderIcon} alt='date'/>{item?.header}</Styled.Span>}
                                </Styled.ItemDetails>
                                <Styled.Header>
                        	        {item?.title}
                                </Styled.Header>
                                
                            </Styled.Item>
                            )

					}else if(item.type === 'pressrelease'){
						return (
                            index < maxItems && (
                                <Styled.Item key={index}>
                                    {item?.header !== '#' && (
                                        <Link 
                                            to={isExternalUrl(item?.subText) ? item?.subText : process.env.GATSBY_DOMAIN_URL + '/in-the-media' + item?.subText}
                                            target={isExternalUrl(item?.subText) ? '_blank' : '_self'}
                                            rel="noreferrer"
                                        >
                                            {documentToReactComponents(
                                                JSON.parse(item?.contentDetails?.raw),
                                                optionsPressReleaseStyle
                                            )}
                                        </Link>
                                    )}
                                    <Styled.ItemDetails>
                                        {item?.header && <Styled.Span><Styled.Icon src={calenderIcon} alt='date' />{item?.header}</Styled.Span>}
                                    </Styled.ItemDetails>
                                    <Link
                                        style={{textDecoration : 'none'}}
                                        to={isExternalUrl(item?.subText) ? item?.subText : process.env.GATSBY_DOMAIN_URL + '/in-the-media' + item?.subText}
                                        target={isExternalUrl(item?.subText) ? '_blank' : '_self'}
                                        rel="noreferrer"
                                    >
                                        <Styled.Header>
                                            {item?.title}
                                        </Styled.Header>
                                    </Link>
                                    {/* {documentToReactComponents(
                                    JSON.parse(item?.contentDetails?.raw),
                                    optionsPressReleaseParaStyle
                                    )} */}
                                </Styled.Item>
                            )
                        );
					
                    }else{
                        
                        return(
                            (index<maxItems) && <Styled.Item key={index}>
                                {item?.header !=='#' && 
                                <a 
                                href={
                                isExternalUrlhref(item?.subText)
                                    ? item?.subText
                                    : addTrailingSlash(process.env.GATSBY_DOMAIN_URL + item?.subText)
                                }
                                target={isExternalUrl(item?.subText) ? '_blank' : '_self'}
                                rel="noreferrer">
                                {
                                documentToReactComponents(
                                JSON.parse(item?.contentDetails?.raw),
                                optionsPressReleaseStyle
                                )
                                }
                                </a>
                                }
                                <Styled.ItemDetails>
                                    {item?.header && <Styled.Span><Styled.Icon src={calenderIcon} alt='date'/>{item?.header}</Styled.Span> }
                                </Styled.ItemDetails>
                                <Styled.Header>
                                    {item?.title}
                                </Styled.Header>
                                
                                {documentToReactComponents(
                                JSON.parse(item?.contentDetails?.raw),
                                optionsPressReleaseParaStyle
                                )}
                                
                            </Styled.Item>
                            )
                    }
                    
                })}

            </Styled.ItemContainer> 
            <Styled.CenterContent>
            <Styled.Expand onClick={() => setShowMore(!showMore)}>{showMore > 3 ?'View Less':''}</Styled.Expand> 
            </Styled.CenterContent>     
    </Styled.MainDiv>
);
};

export default ContentPressRelease;

export const pageQuery = graphql`
query allMediaPageQuery(
$pageIdentifier: String!
$contentfulContent: Boolean!
$contentfulContentList: Boolean!
$contentfulTextWithImage: Boolean!
$contentfulListImagesWithPath: Boolean!
$contentfulListTextWithPath: Boolean!
) {
    allContentfulSolEcolution(
    filter: { identifier: { type: { eq: $pageIdentifier } }, node_locale: {eq: "en-US"} }
) {
        edges {
            node {
                name
                identifier {
                type
                }
                slug
                title
                node_locale
                hasIndex
                sections {
                    ...PageContent @include(if: $contentfulContent)
                    ...ContentList @include(if: $contentfulContentList)
                    ...TextWithImage @include(if: $contentfulTextWithImage)
                    ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
                    ...ListTextWithPath @include(if: $contentfulListTextWithPath)
                }
            }
        }
    }
}
`;