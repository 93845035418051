import styled from "styled-components";

export const MainDiv = styled.div`
  margin-top: 25px;
  padding: 0% 7%;
  scroll-margin-top: 350px;
  @media (max-width: 1440px) {
    scroll-margin-top: 350px;
  }
  @media (max-width: 1024px) {
    scroll-margin-top: 340px;
  }
  @media (max-width: 768px) {
    scroll-margin-top: 290px;
  }
  @media (max-width: 425px) {
    margin-top: 20px;
    scroll-margin-top: 290px;
  }
`;

export const ItemContainer = styled.div`
  display: inline-grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: center;
  align-items: baseline;
  grid-gap: 20px;
  width: 100%;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Item = styled.div`
  position: relative;
  margin-bottom: 10%;
`;

export const Title = styled.h1`
  background-color: #fff;
  padding: 20px;
  font-size: 42px;
  font-weight: bold;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const LineBehind = styled.h2`
  width: 50%;
  text-align: center;

  border-bottom: 2px solid #007dc3;
  line-height: 0.1em;
  margin: 10px 0 20px;
  background-color: #fff;
  @media (max-width: 768px) {
    border-bottom: none;
    width: auto;
  }
`;

export const LineBehindSpan = styled.span`
  background-color: #fff;
  font-size: 52px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
  color:var(--font-color);
  ${LineBehind} {
    background-color: #f5f5f5;
    padding: 0 10px;
  }
  @media (max-width: 1440px) {
    font-size: 34px;
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 768px) {
    font-size: 34px;
    padding:0;
  }
  @media (max-width: 425px) {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 65px;
  @media (max-width: 768px) {
    margin-bottom: 35px;
  }
  @media (max-width: 375px) {
    margin-bottom: 20px;
  }
`;

export const ItemDetails = styled.div`
  display: flex;
  justify-content: end;
  padding: 5px;
  margin-right: 4%;
  text-transform: uppercase;
`;

export const Icon = styled.img`
  padding: 10px;
  padding-bottom: 15px;
`;

export const Header = styled.h4`
  padding: 10px 0px;
  margin-right: 3%;
  text-align: left;
  font: normal normal bold 23px/28px Montserrat;
  letter-spacing: 0px;
  color: var(--font-color);
  text-transform: capitalize;
  opacity: 1;
  text-decoration: none;
  @media (max-width: 1440px) {
    font: normal normal bold 14px Montserrat;
  }

`;

export const Expand = styled.button`
  text-align: center;
  font-size: 24px;
  margin-top: 30px;
  font-weight: bold;
  border: none;
  background: none;
  @media (max-width: 320px) {
    font-size: 18px;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 95%;
  height: 350px;
  margin-bottom: 1rem;
  @media (max-width: 1440px) {
    height: 250px;
  }
`;

export const Paragraph = styled.p`
  font: normal normal normal 18px Montserrat;
  line-height:30px;
  color: var(--font-color);
  word-break: break-word;

  @media (max-width: 1440px) {
    font: normal normal normal 12px Montserrat;
  }

  @media (max-width: 320px) {
    font: normal normal normal 14px Montserrat;
    line-height:24px;
  }
`;

export const ExternalLink = styled.img`
  width: 45px;
  position: absolute;
  filter: invert(1);
  top: 0;
  right: 0;
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 400px;
  @media (max-width: 1440px) {
    height: 200px;
  }
  @media (max-width: 768px) {
    height: 400px;
  }
`;

export const Span = styled.span`
  font-size: 10px;
`;
